import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgLogo from "../images/logo-white-alpha-large.png"
import imgCoach from "../images/coach-blue-blur.jpg"
import imgSupport from "../images/support.jpg"
import "./index.css";
import Helmet from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Bounce back offer requested" />
        <Helmet>
          <script src="https://kit.fontawesome.com/d186c5838e.js" crossorigin="anonymous"></script>
        </Helmet>
        <div>
            <div>
              <div className='header-container' style={{ maxWidth: '100%'}}>
                <div className='header' style={{ maxWidth: '100%'}}>
                  <div style={{ maxWidth: '100%', margin: '0 auto'}}>
                    <Link to='/'><img style={{ maxWidth: `200px`, margin: 0 }} src={imgLogo} /></Link>
                    <div className='nav'>
                      <Link to='/packages/' className='nav-link'>Packages</Link>
                      <Link to='/case-studies/' className='nav-link'>Case studies</Link>
                      <Link to='/about/' className='nav-link'>About</Link>
                      <Link to='/contact/' className='nav-link'>Contact</Link>
                      <span className='nav-item desktop-only'>Sales: 0333 344 9868</span>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', backgroundImage: `url(${imgCoach})`, backgroundColor: '#fbfbfb', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>

                <div className="blog" style={{ marginTop: 100, marginBottom: 100 }}>
                  <div className='headline-block blog-inner'>
                    <h1 className="blog-headline">Offer requested</h1>
                    <p style={{ textAlign: 'center', fontSize: '1.3em', color: '#3f6375', lineHeight: '150%' }}>
                      Thank you for your request. A friendly member of our team will be in touch with more information as soon as possible.
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </Layout>
    );
  }
};

export default BlogPage
